/* eslint-disable */
export default {
    events: [
        {
            id: 1,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 2,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 3,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 4,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 5,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 6,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 7,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 8,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 9,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 10,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 11,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 12,
            image: 'Rectangle 19.png',
            title: 'Beatles',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
    ],
    topEvents: [
        {
            id: 1,
            image: 'Rectangle 20.png',
            title: 'Madonna',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 2,
            image: 'Rectangle 23.png',
            title: 'Metal Group',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
        {
            id: 3,
            image: 'Rectangle 26.png',
            title: 'Sting',
            date: '31 September 2023',
            location: 'Yerevan',
            price: '10.000 - 30.000 AMD'
        },
    ],
    valid: false,
    feedback: {},
}